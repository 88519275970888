<template>
  <div class="page">
    <div class="row">
      <div class="col-5">
        <b-card>
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <div class="row">
              <div class="col-6">
                <b-form-group
                  label="First Name"
                  label-for="firstName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="firstName"
                        v-model="lead.firstName"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="firstName"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group
                  label="Last Name"
                  label-for="lastName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="lastName"
                        v-model="lead.lastName"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="lastName"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="email"
                        v-model="lead.email"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="email"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-form-group
                  label="Phone"
                  label-for="phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="phone"
                        v-model="lead.phone"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="phone"
                        @input="formatPhoneNumber(lead.phone)"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-form-group
                  label="Status"
                  label-for="status"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        id="status"
                        v-model="lead.status"
                        :options="statusOptions"
                        name="status"
                        :clearable="false"
                        :searchable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-input-group></validation-provider>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-form-group
                  label="Method"
                  label-for="method"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Method"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="method"
                        v-model="lead.method"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="method"
                        readonly
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <b-button
              variant="outline-primary"
              class="mb-2"
              :disabled="invalid"
              @click="save"
            >
              Save
            </b-button>
            <b-alert
              v-if="msg"
              variant="danger"
              show
            >
              <div class="alert-body">
                <span>{{ msg }}</span>
              </div>
            </b-alert>
          </validation-observer>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BFormGroup, BInputGroup, BFormInput, BButton, BAlert,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCard, BFormGroup, BInputGroup, ValidationProvider, ValidationObserver, BFormInput, BButton, vSelect, BAlert,
  },
  data() {
    return {
      statusOptions: [
        'Follow Up',
        'Initial Contact',
        'Completed',
      ],
      required,
      lead: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        status: 'Follow Up',
        method: 'Manual',
      },
      msg: '',
    }
  },
  methods: {
    formatPhoneNumber(data) {
      const cleaned = ('' + data).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        this.lead.phone = match[1] + '.' + match[2] + '.' + match[3]
      }
    },
    save() {
      this.$store.dispatch('leads/createLead', this.lead).then(response => {
        if (response.data.code === 200) {
          this.$router.push({ name: 'lead-detail', params: { id: response.data.data.id } })
        } else {
          this.msg = response.data.message
        }
      }).catch(err => {
        console.error(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select{
  width:100%;

}
</style>
